@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
* {
    font-family: Poppins;
    margin: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
    margin: 0;
    font-family: Poppins;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: Poppins;
}

li {
    list-style: none;
}

nav {
    padding: 0 !important;
}
